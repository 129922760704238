import { NavLink } from 'react-router-dom'

import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { UtilService } from 'common/services/utilService'
import type { TAG_STATUS } from 'common/types/tagStatusType'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import { PathUtils } from 'routes/routes.utils'
import styles from './billingHistory.module.scss'
import dayjs from 'dayjs'

export const HISTORY_TABLE_COLUMNS = [
  {
    title: 'Invoice',
    dataIndex: 'invoice_number',
    key: 'invoice_number',
    type: 'text',
    editable: false,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      a.invoice_number.localeCompare(b.invoice_number),
  },
  {
    title: 'Booking ID',
    dataIndex: 'lease_id',
    key: 'lease_id',
    type: 'text',

    editable: false,
    render: (_: string, data: ISettingHistory) => {
      const getLeasePath = (siteId: string, leaseId: string, isPromotion: boolean) => {
        if (isPromotion) {
          return PathUtils.getPromotionDetails(siteId, leaseId)
        }
        return PathUtils.getBookingDetails(siteId, leaseId)
      }

      const isPromotion = !!data.is_promotion
      return (
        <NavLink
          className='navigation-link'
          to={getLeasePath(data.db_connection, data.lease_id, isPromotion)}>
          {data.lease_id}
          <span className={styles.parentLeaseType} data-promotion={isPromotion}>
            {isPromotion ? 'Promotion' : data.lease_type_name}
          </span>
        </NavLink>
      )
    },
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.lease_id.localeCompare(b.lease_id),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    render: (amount: number) => UtilService.numberToDollar(amount),
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.amount - b.amount,
  },
  {
    title: 'Points',
    dataIndex: 'points',
    key: 'points',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.points - b.points,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    type: 'text',
    editable: false,
    render: (date: string) => dayjs(date).format("ddd, MMM DD 'YY"),
    sorter: (a: ISettingHistory, b: ISettingHistory) => Date.parse(a.date) - Date.parse(b.date),
  },
  {
    title: 'Deposit',
    dataIndex: 'deposit',
    key: 'deposit',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.deposit, b.deposit),
  },
  {
    title: 'Extra Charge',
    dataIndex: 'extra_charge',
    key: 'extra_charge',
    type: 'text',
    width: 120,
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.extra_charge, b.extra_charge),
  },
  {
    title: 'Deposit Refund',
    dataIndex: 'deposit_refund',
    key: 'deposit_refund',
    type: 'text',
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.deposit_refund, b.deposit_refund),
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    type: 'text',
    editable: false,
    render: (status_name: string) => <TagStatus status={status_name as TAG_STATUS} />,
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.status_name.localeCompare(b.status_name),
  },
]
