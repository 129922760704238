import './payment-history.scss'

import { type FC, memo, useEffect, useState } from 'react'

import { Table } from 'common/components/Table/Table'
import type { ITriggerPagRequest } from 'common/interfaces/IRequestResponse'

import { SETTINGS_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import { useLazyPaymentHistoryQuery } from 'features/SettingsOld/state/api/settingsApi'

import { paymentHistoryColumns } from './payment-history.columns'
import type { ObjectType } from 'common/interfaces/object'
import type { TableParamsType } from 'common/interfaces/ITable'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { useParams } from 'react-router-dom'
import { TableSearchAndFilters } from 'common/components/TableSearchAndFilters/TableSearchAndFilters'
import { Col } from 'antd'

export const PaymentHistory: FC = memo(() => {
  const [columns, setColumns] = useState<ObjectType[]>(paymentHistoryColumns)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const { siteId } = useParams()

  const [
    fetchPaymentHistory,
    { data: paymentHistory, isFetching: isLoadingSiteHistory },
  ]: ITriggerPagRequest<ISettingHistory[]> = useLazyPaymentHistoryQuery()

  useEffect(() => {
    if (paymentHistory?.total_items >= 0)
      setTableParams({ ...tableParams, total: paymentHistory.total_items })
  }, [paymentHistory])

  useEffect(() => {
    if (tableParams.sorters) {
      setColumns((prev) =>
        prev.map((column) => ({ ...column, sortOrder: tableParams.sorters?.[column.key]?.order })),
      )
    }
  }, [tableParams.sorters])

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchPaymentHistory({
      site: siteId,
      tableParams: {
        ...tableParams,
        search: tableParams.debouncedValue?.length >= 2 ? tableParams.debouncedValue : '',
      },
    })
  }, [
    tableParams.pageSize,
    tableParams.current,
    tableParams.debouncedValue,
    tableParams.sorters,
    siteId,
  ])

  return (
    <div className='payment-history'>
      <h1 className='page-title'>{SETTINGS_CONSTANTS.PAYMENTS_HISTORY}</h1>
      <TableSearchAndFilters
        setTableParams={setTableParams}
        setColumns={setColumns}
        tableParams={tableParams}
        inputHint='Search by payment, amount, status'
      />
      <Table
        itemKey='id'
        haveActions={false}
        tableParams={tableParams}
        setTableParams={setTableParams}
        dataSource={paymentHistory?.items}
        columns={columns}
        isLoading={isLoadingSiteHistory}
      />
    </div>
  )
})
