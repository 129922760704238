import 'styles/index.scss'
import type { FC } from 'react'
import { useMemo } from 'react'

import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { withProviders } from './app/hocs'
import { useAuth } from './app/providers'

import { Payment } from './features/Payment'

import { Auth } from './pages/Auth'
import { Guest } from './pages/Guest'
import { PATH_SEGMENT } from './routes/pathSegments'
import { ProtectedRoute } from './routes/ProtectedRoutes/ProtectedRoute'
import { PublicRoute } from './routes/ProtectedRoutes/PublicRoute'
import { generateRouter, PathUtils } from './routes/routes.utils'
import { theme } from './theme'
import Popup from 'common/components/Popup'
import { ErrorPage } from './common/components/FallbackComponent/ErrorPage/ErrorPage'
import { Checkout } from 'features/Home/Promotion/Checkout/Checkout'
import ScrollToTop from 'common/components/ScrollToTop/ScrollToTop'
import { Navigate } from 'react-router-dom'
import { AuthIdentity, AuthValidationStatus } from './features/Auth'
import { SiteRedirect } from './pages/SiteRedirect'
import { Main } from './pages/Main'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(timezone)

const App: FC = () => {
  const { user } = useAuth()

  const routesConfig = useMemo(
    () => ({
      [`/${PATH_SEGMENT.LOGIN}/*`]: <Navigate to={PathUtils.getLogin()} />,
      [`/${PATH_SEGMENT.REGISTER}/*`]: <Navigate to={PathUtils.getRegister()} />,
      [`${PATH_SEGMENT.GUEST}/*`]: (
        <PublicRoute>
          <Guest />
        </PublicRoute>
      ),
      [`${PATH_SEGMENT.ERROR}/:code`]: <ErrorPage />,
      [PATH_SEGMENT.ROOT]: (
        <ProtectedRoute>
          <SiteRedirect />
        </ProtectedRoute>
      ),
      [`${PATH_SEGMENT.ROOT}:siteId/*`]: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      ),
      [`/:siteId/${PATH_SEGMENT.CHECKOUT}/*`]: (
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      ),
      [`/:siteId/promotion/:bookingId/${PATH_SEGMENT.CHECKOUT}/*`]: (
        <ProtectedRoute>
          <Checkout />
        </ProtectedRoute>
      ),
      [PATH_SEGMENT.VALIDATE_IDENTITY]: <AuthIdentity />,
      [PATH_SEGMENT.VALIDATION_PROCESSING]: <AuthValidationStatus />,
      [`${PATH_SEGMENT.AUTH}/*`]: (
        <PublicRoute>
          <Auth />
        </PublicRoute>
      ),
    }),
    [user],
  )

  return (
    <ConfigProvider theme={theme}>
      <Popup />
      <ScrollToTop />
      {generateRouter(routesConfig)}
    </ConfigProvider>
  )
}

export default withProviders(App)
