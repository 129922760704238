import { TABLE_COLUMN_TYPES } from 'common/constants/tableConstants'
import dayjs from 'dayjs'

export const INFO_DOCUMENTS_TABLE_COLUMNS = [
  {
    title: 'Document name',
    dataIndex: 'name',
    key: 'name',
    inputType: TABLE_COLUMN_TYPES.TEXT,
    sorter: (a: any, b: any) => a.name && b.name && a.name.localeCompare(b.name),
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: any, b: any) => Date.parse(a.created_at) - Date.parse(b.created_at),
    render: (created_at: string) => dayjs(created_at).format("ddd, MMM DD 'YY"),
  },
]
