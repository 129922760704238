import type { ChangeEvent, FC } from 'react'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { Col, Row } from 'antd'

import searchIcon from 'assets/icons/search.svg'
import { ReactComponent as FilterResetIcon } from 'assets/icons/filter-reset.svg'

import { Input } from 'common/components/Input/Input'
import { Button } from 'common/components/Button/Button'
import type { TableSearchAndFiltersProps } from './tableSearchAndFilters.types'

import styles from './tableSearchAndFilters.module.scss'

export const TableSearchAndFilters: FC<TableSearchAndFiltersProps> = memo(
  ({ setTableParams, inputHint, tableParams, setColumns, addButton }) => {
    const [searchValue, setSearchValue] = useState('')

    useDebounce(
      () => {
        setTableParams((prev) => ({ ...prev, current: 1, debouncedValue: searchValue }))
      },
      300,
      [searchValue],
    )

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    }, [])

    useEffect(() => {
      if (tableParams.sorters) {
        setColumns((prev) =>
          prev.map((column) => ({
            ...column,
            sortOrder: tableParams.sorters?.[column.key]?.order,
          })),
        )
      }
    }, [tableParams.sorters])

    const handleResetSorters = () => {
      setColumns((prev) => prev.map((column) => ({ ...column, sortOrder: null })))

      setTableParams((prev) => {
        return {
          ...prev,
          sorters: {},
          search: '',
        }
      })
      setSearchValue('')
    }

    const isDisabled =
      (!tableParams.sorters || !Object.keys(tableParams.sorters).length) &&
      !tableParams.debouncedValue

    return (
      <Row align='middle' gutter={{ xs: 0, md: 32 }} className={styles.searchWrapper}>
        <Col xs={{ order: 2, span: 24 }} md={{ order: 1, flex: 'none' }}>
          <Input
            value={searchValue}
            name='search'
            parentClassName={styles.searchInput}
            prefix={<img src={searchIcon} alt='Search' className={styles.searchImg} />}
            onChange={handleSearch}
            placeholder={inputHint}
          />
          <button disabled={isDisabled} className={styles.resetFilter} onClick={handleResetSorters}>
            <FilterResetIcon width={16} height={16} />
            Reset filters
          </button>
        </Col>
        {addButton && (
          <Col xs={{ order: 1, span: 24 }} md={{ order: 2, flex: 'none' }}>
            <div className={styles.addButton}>
              <Button onClick={addButton.onClick}>{addButton.label}</Button>
            </div>
          </Col>
        )}
      </Row>
    )
  },
)
