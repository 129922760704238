import type { FC } from 'react'
import { useId } from 'react'

import { Select as AntSelect, Skeleton } from 'antd'
import classNames from 'classnames'

import stateIcon from 'assets/icons/state-dropdown.svg'

import { Label } from 'common/components/Label/Label'
import Spinner from 'common/components/Spinner/Spinner'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { SELECT_CONSTANTS } from 'common/constants/selectConstants'
import type { ISelect } from 'common/interfaces/ISelect'
import { UtilService } from 'common/services/utilService'

import styles from './select.module.scss'
import { Show } from 'common/components/Show/Show'

const { Option } = AntSelect

export const Select: FC<ISelect> = ({
  label,
  isLoading,
  listOptions = [],
  placeholder,
  loading,
  defaultValue,
  proportion = SELECT_CONSTANTS.MEDIUM,
  required,
  error,
  ...rest
}) => {
  const id = useId()

  return (
    <div className={styles.container}>
      <Label id={id} label={label} required={required} />

      {isLoading ? (
        <div
          className={classNames(
            styles.loadingContainer,
            styles[`parent${UtilService.capitalizeFirstLetter(proportion)}`],
          )}>
          <Skeleton.Input active className={styles.loadingSkeleton} />
        </div>
      ) : (
        <AntSelect
          {...rest}
          id={id}
          key={defaultValue}
          defaultValue={defaultValue}
          placeholder={placeholder || 'None'}
          suffixIcon={
            loading ? (
              <Spinner isLoading />
            ) : (
              <img src={stateIcon} alt={ALT_CONSTANTS.STATUS_ICON} />
            )
          }
          optionFilterProp='children'
          className={classNames(
            styles.parent,
            styles[`parent${UtilService.capitalizeFirstLetter(proportion)}`],
            { [styles.parentMultiple]: rest.mode === 'multiple' },
            { [styles.parentDisabled]: rest.disabled },
          )}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }>
          {listOptions?.map((option: any, index: number) => {
            return (
              <Option key={index} value={option.code || option}>
                {option.name || option}
              </Option>
            )
          })}
        </AntSelect>
      )}
      <Show when={error}>
        <p className='errorMessage'>{error}</p>
      </Show>
    </div>
  )
}
