import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { ILevels } from 'common/interfaces/ILevels'
import type { IOption, IOptionWithId } from 'common/interfaces/IOption'
import type { ISelector } from 'common/interfaces/ISelector'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

export enum ROOT_TAGS {
  SITES = 'Sites',
}

export const rootApi = createApi({
  reducerPath: REDUCERS.ROOT,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchStates: build.query<IOption[], void>({
      query: () => ({
        url: '/states',
      }),
      transformResponse: (data: { states: IOption[] }): IOption[] =>
        data.states.map((state: IOption) => ({
          ...state,
          name: state.short_name || state.name,
        })),
    }),
    fetchCountries: build.query<IOption[], void>({
      query: () => ({
        url: '/countries',
      }),
      transformResponse: (data: IOptionWithId[]): IOption[] =>
        data
          .map(({ name, id, code }: IOptionWithId) => ({
            code: id,
            name: `${code}, ${name}`,
          }))
          .sort((a: IOption) => (a.name.includes('United States') ? -1 : 0)),
    }),
    fetchSites: build.query<ISelector, void>({
      query: () => ({
        url: '/site-selector',
      }),
      providesTags: [ROOT_TAGS.SITES],
    }),
    setDefaultSite: build.mutation({
      query: (data) => ({
        data,
        url: '/site-selector',
        method: HTTP_METHODS.POST,
      }),
    }),
    fetchLevels: build.query<ILevels, void>({
      query: () => ({
        url: '/levels',
      }),
      providesTags: ['Levels'],
    }),
  }),
})

export const {
  useFetchSitesQuery,
  useFetchStatesQuery,
  useFetchCountriesQuery,
  useLazyFetchSitesQuery,
  useLazyFetchLevelsQuery,
  useFetchLevelsQuery,
  useSetDefaultSiteMutation,
} = rootApi
