import './rooms.scss'

import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Popover, Row, Skeleton } from 'antd'
import dayjs from 'dayjs'

import { useScreenWidth } from 'app/hooks/useScreenWidth'

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'

import { Button } from 'common/components/Button/Button'
import Show from 'common/components/Show2/Show'

import { useLazyFetchBookingsByDateRangeQuery } from 'features/Home/Promotion/state/promotionsApi'
import type { RoomsProps } from './rooms.types'
import { Calendar } from './Calendar/Calendar'
import { CalendarCard } from './CalendarCard/CalendarCard'
import { MonthView } from './MonthView/MonthView'

const formatCalendarWeek = (
  dateRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs },
  sign: -1 | 1 | 0,
  short: boolean = false,
) => {
  const format = short ? 'MMM D' : 'MMM D'
  return `${dayjs(dateRange.startDate).add(sign, 'week').format(format)} - ${dayjs(
    dateRange.endDate,
  )
    .add(sign, 'week')
    .format('Do')}`
}

const getShortName = (name: string) => {
  return name
    .split(' ')
    .map((r) => r.charAt(0))
    .join('')
}

export const getRoomName = (name: string, shouldParse: boolean) => {
  const roomDefName = String(name).slice(0, name.lastIndexOf(' '))
  const roomIdentifier = String(name).slice(name.lastIndexOf(' '))
  const roomShortName = getShortName(roomDefName)
  const parsedShortRoomName = `${roomShortName} ${roomIdentifier}`
  return shouldParse ? parsedShortRoomName : name
}

export const Rooms: FC<RoomsProps> = memo(({ promotion, isSuccess, isLoading }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const { breakpoints } = useScreenWidth()
  const { bookingId, siteId } = useParams()

  const [
    fetchBookingsByDateRange,
    { data: bookingsData, isLoading: isLoadingBookings, isFetching: isFetchingBookings },
  ] = useLazyFetchBookingsByDateRangeQuery()

  const isMobileScreen = breakpoints.xs
  const tz = useMemo(() => promotion?.time_zone, [promotion?.time_zone])

  const dateRange = (() => {
    const startDate = dayjs(currentDate || new Date()).startOf('week')
    const endDate = dayjs(currentDate || new Date()).endOf('week')
    return {
      startDate,
      endDate,
    }
  })()

  useEffect(() => {
    if (!isLoading && !isLoadingBookings && isSuccess) {
      setIsFirstLoad(false)
    }
  }, [isLoadingBookings, isLoading, isSuccess])

  useEffect(() => {
    if (promotion?.code) {
      const currentDate = dayjs().tz(tz)
      const startDate = dayjs(promotion.start_date).tz(tz)
      const dateToSet = currentDate.isAfter(startDate) ? currentDate : startDate
      setCurrentDate(dateToSet)
    }
  }, [promotion?.code, tz])

  const isPrevDisabled = dayjs(dateRange.startDate)
    .subtract(1, 'day')
    .isBefore(dayjs(promotion?.start_date))
  const isNextDisabled = dayjs(dateRange.endDate).add(1, 'day').isAfter(dayjs(promotion?.end_date))

  const currentDateRange = {
    startDate: dateRange?.startDate?.format('YYYY-MM-DD'),
    endDate: dateRange?.endDate?.format('YYYY-MM-DD'),
  }

  const handleUpdateDate = (sign: -1 | 1) => {
    setCurrentDate((prev) => dayjs(prev).add(sign, 'week'))
  }

  useEffect(() => {
    if (!currentDate || !promotion?.code || !siteId || !bookingId) {
      return
    }

    fetchBookingsByDateRange({
      siteId,
      bookingId,
      ...currentDateRange,
    })
  }, [currentDate, siteId, bookingId, promotion?.code])

  useEffect(() => {
    const handleScroll = () => {
      setIsPopoverVisible(false)
    }

    const modal = document.querySelector('.ant-modal-wrap')
    const elementToCheck = modal || window

    elementToCheck.addEventListener('scroll', handleScroll)
    return () => {
      elementToCheck.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className='promotion-details-rooms-container'>
      <Show>
        <Show.When isVisible={isFirstLoad || isLoadingBookings}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Skeleton.Button active className='calendar-skeleton' />
            </Col>

            <Col xs={24}>
              <Skeleton.Button active className='rooms-skeleton' />
            </Col>
          </Row>
        </Show.When>

        <Show.When isVisible={isSuccess && !isLoading}>
          <div className='rooms-main-container'>
            <Row>
              <Popover
                overlayClassName='month-view'
                align={{ offset: [0, 10] }}
                destroyTooltipOnHide
                placement='bottomLeft'
                showArrow={false}
                trigger='click'
                open={isPopoverVisible}
                onOpenChange={(visible) => setIsPopoverVisible(visible)}
                title={
                  <MonthView
                    startDate={promotion?.start_date}
                    endDate={dayjs(promotion?.end_date).add(1, 'day').format('YYYY-MM-DD')}
                    handleChangeDate={(d) => setCurrentDate(dayjs(d))}
                    currentDate={currentDate}
                  />
                }>
                <Button className='month-view-button'>
                  <>
                    <CalendarIcon width={20} height={20} />
                    Month
                  </>
                </Button>
              </Popover>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <div className='calendar-container'>
                  <div className='arrow-wrapper'>
                    <button
                      disabled={isPrevDisabled}
                      className='arrow-container'
                      onClick={() => handleUpdateDate(-1)}>
                      <ArrowIcon />
                    </button>
                    <span className='date-range'>
                      {formatCalendarWeek(dateRange, -1, isMobileScreen)}
                    </span>
                  </div>
                  <div className='rooms-container'>
                    {bookingsData?.convention?.rooms?.map((room) => {
                      return (
                        <CalendarCard
                          key={room.name}
                          room={{
                            ...room,
                            name: `${room.name} (${getShortName(room.name)})`,
                          }}
                        />
                      )
                    })}
                  </div>

                  <div className='arrow-wrapper' data-end>
                    <button
                      disabled={isNextDisabled}
                      className='arrow-container'
                      data-rotate
                      onClick={() => handleUpdateDate(1)}>
                      <ArrowIcon />
                    </button>
                    <span className='date-range'>
                      {formatCalendarWeek(dateRange, 1, isMobileScreen)}
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={24} className='rooms-container-mobile'>
                {bookingsData?.convention?.rooms?.map((room) => {
                  return (
                    <Row className='item' key={room.name}>
                      <CalendarCard
                        key={room.name}
                        room={{ ...room, name: `${room.name} (${getShortName(room.name)})` }}
                        className='mobile-card'
                      />
                    </Row>
                  )
                })}
              </Col>
            </Row>
            <Row>
              <Calendar
                promotion={promotion}
                bookingsData={bookingsData}
                isLoading={isFetchingBookings}
              />
            </Row>
          </div>
        </Show.When>
      </Show>
    </section>
  )
})
